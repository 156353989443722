import { useFormik } from "formik";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { FaPlus, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import { httpReauest } from "../../../utils/httpRequest";
import * as Yup from "yup";
import { BASE_URL, CDN_BASE_URL } from "../../../config";
import Loading from "../Loading";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import ReactSelect from "react-select";
import CustomModal from "../Modal";
import moment from "moment/moment";
import ReactQuill from "react-quill";

export const PortfolioCategory = [
  { name: "ری اکت (وب اپ)", id: 1 },
  { name: "وردپرس", id: 2 },
];
export const PortfolioSubCategory = [
  { category: 1, name: "شرکتی" },
  { category: 1, name: "فروشگاهی" },
  { category: 1, name: "سفارشی" },
  { category: 1, name: "پلتفرم" },
  { category: 1, name: "خارجی" },
  { category: 2, name: "شرکتی" },
  { category: 2, name: "فروشگاهی" },
  { category: 2, name: "خارجی" },
];

const EditPortfolioForm = () => {
  const [img, setImg] = useState();
  const [images, setImages] = useState([]);
  const [previews, setPreview] = useState([]);
  const [urls, seturls] = useState([]);
  const [logo, setlogo] = useState();
  const [previewLogo, setPreviewLogo] = useState();
  const [previewImg, setPreviewImg] = useState();
  const [vehicles, setVehicles] = useState();
  const [selectedVehicle, setselectedVehicle] = useState();
  const [loading, setloading] = useState();
  const [file, setfile] = useState();
  const [show, setshow] = useState();
  const [text, settext] = useState();
  const [subCategorys, setsubCategorys] = useState([]);

  function getFileName(filePath) {
    // جدا کردن مسیر با استفاده از '/' و '\' و سپس گرفتن آخرین بخش
    const parts = filePath.split(/[/\\]/);
    const fileNameWithExtension = parts.pop(); // آخرین بخش را می‌گیرد

    // حذف شماره‌ها و نگه‌داشتن فقط نام فایل و پسوند
    const fileName = fileNameWithExtension.replace(/-.*(?=\.)/, ""); // حذف همه چیز بعد از '-' و قبل از '.'

    return fileName;
  }

  const toDataURL = (url) => {
    return fetch(url)
      .then((response) => {
        // بررسی وضعیت پاسخ
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const file = new File([blob], getFileName(url), {
              type: blob.type || "application/octet-stream", // نوع MIME پیش‌فرض
            });
            resolve(file);
          };
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      });
  };

  const VALUE_SIGN_VALIDATION = () => {
    return Yup.object({
      name: Yup.string().required("الزامی است"),
    });
  };

  async function deleteImg(index) {
    setPreview((students) => students.filter((s, i) => i != index));
    setImages((students) => students.filter((s, i) => i != index));
  }

  const { user, setUser } = useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      name: "",
      type: "",
      category: "",
      subCategory: "",
    },
    validationSchema: VALUE_SIGN_VALIDATION,
    onSubmit: async (values) => {
      setloading(true);

      const formdata = new FormData();
      formdata.append("name", values.name);
      formdata.append("type", values.type);
      formdata.append("category", values.category);
      formdata.append("subCategory", values.subCategory);
      formdata.append("desc", values.desc);
      if (img) {
        formdata.append("img", img, img?.name);
      }

      if (logo) {
        formdata.append("logo", logo, logo?.name);
      }

      if (images) {
        for (let index = 0; index < images.length; index++) {
          formdata.append("images", images[index], images[index]?.name);
        }
      }

      await fetch(BASE_URL + "/portfolio/" + selectedVehicle?._id, {
        method: "PUT",
        body: formdata,
        headers: { "x-access-token": user.token },
      }).then(async (dat) => {
        if (dat?.status == 201) {
          toast.success("نمونه کار تغییر یافت");
          setloading(false);
          formik.resetForm();
          setImg(null);
          setImages([]);
          setPreviewImg(null);
          setPreview([]);
        } else {
          setloading(false);
          const message = await dat.json();
          toast.error(message?.message);
        }
      });
    },
  });

  useEffect(() => {
    getData();
  }, []);

  function handleImg(e) {
    const fils = e.target.files[0];
    if (fils.size > 2031517) {
      toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
    } else {
      setImg(fils);
      setPreviwImage(fils);
    }
  }

  function handleLogo(e) {
    const fils = e.target.files[0];
    if (fils.size > 2031517) {
      toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
    } else {
      setlogo(fils);
      setPreviwLogo(fils);
    }
  }

  function handleImages(e) {
    const fils = e.target.files;
    if (fils) {
      for (let index = 0; index < fils?.length; index++) {
        if (fils[index].size > 2031517) {
          toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
        } else {
          setImages((current) => [...current, fils[index]]);
          setPreviwImages(fils[index]);
        }
      }
    }
  }

  async function setPreviwImages(i) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreview((current) => [...current, reader.result]);
    };
    reader.readAsDataURL(i);
  }

  async function setPreviwImage(imgInput) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreviewImg(reader.result);
    };
    reader.readAsDataURL(imgInput);
  }
  async function setPreviwLogo(imgInput) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreviewLogo(reader.result);
    };
    reader.readAsDataURL(imgInput);
  }

  async function getData() {
    await httpReauest("GET", "/portfolio", {}, {}).then(({ data }) => {
      setVehicles(data.data);
    });
  }
  async function handleChange(e) {
    setImages([]);
    setPreview([]);
    const { data } = await httpReauest("GET", "/portfolio/" + e, {}, {});

    const newVehicle = data?.data;

    setselectedVehicle(newVehicle);
    formik.setFieldValue("name", newVehicle?.name);
    formik.setFieldValue("type", newVehicle?.type);
    formik.setFieldValue("category", newVehicle?.category);
    formik.setFieldValue("subCategory", newVehicle?.subCategory);
    settext(newVehicle?.desc);

    setPreviewImg(CDN_BASE_URL + newVehicle?.img);
    setPreviewLogo(CDN_BASE_URL + newVehicle?.logo);

    newVehicle?.images?.map((e) => {
      toDataURL(CDN_BASE_URL + e)
        .then((file) => {
          setImages((current) => [...current, file]);
        })
        .catch((e) => {
          console.log(e);
        });
      setPreview((current) => [...current, CDN_BASE_URL + e]);
    });
  }

  async function handleDelete(params) {
    await fetch(BASE_URL + "/event/" + selectedVehicle?._id, {
      method: "DELETE",
      headers: { "x-access-token": user.token },
    })
      .then((dat) => {
        if (dat?.status == 201) {
          toast.success("نمونه کار حذف شد");
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
  }

  useEffect(() => {
    if (formik.values.category) {
      const finded = PortfolioCategory.find(
        (e) => e.name === formik.values.category
      );

      const filtered = PortfolioSubCategory.filter(
        (item) => item.category === finded.id
      );
      setsubCategorys(filtered);
    }
  }, [formik.values.category]);

  return (
    <div>
      <CustomModal show={show} onHide={() => setshow(false)} fit>
        <div className="w-[200px] flex justify-center flex-col items-center gap-2">
          <div>از حذف نمونه کار اطمینان دارید؟</div>

          <button
            onClick={() => {
              handleDelete();
              setshow(false);
            }}
            className="btn-brand"
          >
            حذف
          </button>
        </div>
      </CustomModal>
      <h2 className="text-[20px] font-bold p-3">فرم تغییر نمونه کار</h2>
      <h2 className="text-[20px] mb-8 font-bold px-3">
        نمونه کار ثبت شده:{vehicles?.length}
      </h2>
      <form
        onSubmit={formik.handleSubmit}
        className="grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-4 gap-6"
      >
        <span className="relative">
          <select
            id="vehicle"
            name="vehicle"
            value={selectedVehicle?._id}
            onChange={(e) => handleChange(e.target.value)}
            className={` w-full form-brand h-[48px]`}
          >
            <option>{loading && <Loading />}</option>
            {vehicles?.map((e) => {
              return <option value={e._id}>{e.name}</option>;
            })}
          </select>
          <p className="on-border font-bold">انتخاب نمونه کار*</p>
        </span>

        {selectedVehicle && (
          <>
            <span className="relative">
              <input
                id="name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-full form-brand h-[48px]"
              />

              {formik.errors.name && formik.touched.name && (
                <small style={{ color: "red", fontSize: "14px" }}>
                  {formik.errors.name}
                </small>
              )}
              <p className="on-border font-bold">نام نمونه کار*</p>
            </span>

            <span className="relative">
              <select
                id="type"
                name="type"
                value={formik.values.type}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-full form-brand h-[48px]"
              >
                <option></option>
                <option>مشاوره</option>
                <option>طراحی سایت و سئو</option>
                <option>تبلیغات</option>
                <option>کوچینگ</option>
                <option>تولید محتوا</option>
                <option>فرنچایز</option>
              </select>
              {formik.errors.type && formik.touched.type && (
                <small style={{ color: "red", fontSize: "14px" }}>
                  {formik.errors.type}
                </small>
              )}
              <p className="on-border font-bold">خدمت*</p>
            </span>

            <span className="relative">
              <select
                id="category"
                name="category"
                value={formik.values.category}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-full form-brand h-[48px]"
              >
                <option></option>
                {PortfolioCategory.map((item) => (
                  <option>{item.name}</option>
                ))}
              </select>
              {formik.errors.category && formik.touched.category && (
                <small style={{ color: "red", fontSize: "14px" }}>
                  {formik.errors.category}
                </small>
              )}
              <p className="on-border font-bold">دسته بندی*</p>
            </span>

            <span className="relative">
              <select
                id="subCategory"
                name="subCategory"
                value={formik.values.subCategory}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-full form-brand h-[48px]"
              >
                <option></option>
                {subCategorys.map((item) => (
                  <option>{item.name}</option>
                ))}
              </select>
              {formik.errors.subCategory && formik.touched.subCategory && (
                <small style={{ color: "red", fontSize: "14px" }}>
                  {formik.errors.subCategory}
                </small>
              )}
              <p className="on-border font-bold">زیر دسته بندی*</p>
            </span>

            <label
              htmlFor="img"
              className="relative w-full border-dashed border rounded-[10px] p-3 h-[200px] cursor-pointer border-[#444]"
            >
              {previewImg ? (
                <img
                  src={previewImg}
                  className="w-full h-full object-contain"
                  alt=""
                />
              ) : (
                <FaPlus className="center" size={30} />
              )}

              <p className="on-border font-bold">تصویر اصلی</p>
            </label>
            <label
              htmlFor="logo"
              className="relative w-full border-dashed border rounded-[10px] p-3 h-[200px] cursor-pointer border-[#444]"
            >
              {previewLogo ? (
                <img
                  src={previewLogo}
                  className="w-full h-full object-contain"
                  alt=""
                />
              ) : (
                <FaPlus className="center" size={30} />
              )}

              <p className="on-border font-bold">لوگو</p>
            </label>
            <input
              onChange={(e) => {
                handleLogo(e);
              }}
              type="file"
              className="hidden"
              id="logo"
              accept="image/png, image/jpg, image/jpeg"
            />

            <input
              onChange={(e) => {
                handleImg(e);
              }}
              type="file"
              className="hidden"
              id="img"
              accept="image/png, image/jpg, image/jpeg"
            />

            <span dir="ltr" className="relative col-span-full">
              <p className="font-bold text-right">توضیحات</p>
              <ReactQuill
                theme="snow"
                id="desc"
                value={text}
                onChange={(e) => settext(e)}
              />
            </span>
            <div className="relative col-span-full w-full border-dashed border rounded-[10px] p-3 min-h-[340px]  border-[#444]">
              {previews?.length > 0 ? (
                <div
                  className="p-3"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "15px",
                  }}
                >
                  {previews &&
                    previews.map((src, i) => {
                      return (
                        <span className="relative">
                          <img
                            src={src}
                            style={{
                              width: "150px",
                              height: "150px",
                              objectFit: "contain",
                            }}
                            alt=""
                          />
                          <FaTrash
                            color="red"
                            className="top-0 right-0 absolute"
                            onClick={() => deleteImg(i)}
                          />
                        </span>
                      );
                    })}
                  <label htmlFor="images" className="cursor-pointer">
                    <FaPlus size={50} />
                  </label>
                </div>
              ) : (
                <label htmlFor="images" className="cursor-pointer">
                  <FaPlus className="center" size={50} />
                </label>
              )}

              <p className="on-border font-bold">تصاویر</p>
            </div>
            <input
              onChange={(e) => {
                handleImages(e);
              }}
              type="file"
              className="hidden"
              id="images"
              accept="image/png, image/jpg, image/jpeg"
            />
            <span className="col-span-full flex gap-4">
              {loading ? (
                <Loading />
              ) : (
                <>
                  <button className="btn-brand">تغییر نمونه کار</button>
                  <button
                    type="button"
                    onClick={() => setshow(true)}
                    className=" btn-brand-outline"
                  >
                    {" "}
                    حذف نمونه کار
                  </button>
                </>
              )}
            </span>
          </>
        )}
      </form>
    </div>
  );
};

export default EditPortfolioForm;
