import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { httpReauest } from "../../utils/httpRequest";
import { toast } from "react-toastify";
import { FaCheck } from "react-icons/fa";
import { Link } from "react-router-dom";
import { CDN_BASE_URL } from "../../config";
import Loading from "./Loading";

const TableBuyProducts = () => {
  const { user, setUser } = useContext(AuthContext);

  const [data, setData] = useState([]);
  const [selected, setselected] = useState();
  const [loading, setloading] = useState();

  async function getData(params) {
    setloading(true);
    await httpReauest("GET", "/product/sign", {}, {}).then((res) => {
      setselected(res.data.data);
    });
    setloading(false);
  }
  useEffect(() => {
    getData();
  }, []);

  return (
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
      {selected && (
        <>
          <h2 className="title my-3">کاربر ها ({selected?.length})</h2>

          <table class="w-full text-sm text-left rtl:text-right text-blue-100 dark:text-blue-100">
            <thead class="text-xs text-white uppercase bg-orange-600 dark:text-white">
              <tr>
                <th scope="col" class="px-6 py-3">
                  نام
                </th>
                <th scope="col" class="px-6 py-3">
                  ایمیل
                </th>

                <th scope="col" class="px-6 py-3">
                 قیمت محصول
                </th>

                <th scope="col" class="px-6 py-3">
                  تصویر پرداخت
                </th>
              </tr>
            </thead>
            <tbody>
              {selected?.map((req) => {
                return (
                  <tr class="bg-orange-500 border-blue-40">
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-blue-50 whitespace-nowrap dark:text-blue-100"
                    >
                      {req?.name}
                    </th>
                    <td class="px-6 py-4">{req?.email}</td>
                    <td class="px-6 py-4">
                      {req?.product?.price?.toLocaleString()}تومان
                    </td>
                    <td class="px-6 py-4">
                      <Link
                        to={CDN_BASE_URL + req?.payment}
                        target="_blank"
                        className="btn-brand w-fit"
                      >
                        مشاهده
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default TableBuyProducts;
