import { useFormik } from "formik";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { FaPlus, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import { httpReauest } from "../../../utils/httpRequest";
import * as Yup from "yup";
import { BASE_URL } from "../../../config";
import Loading from "../Loading";
import { AuthContext } from "../../../context/AuthContext";
import Select from "react-select";

const CreateProductForm = () => {
  const [img, setImg] = useState();
  const [images, setImages] = useState([]);
  const [previews, setPreview] = useState([]);
  const [previewImg, setPreviewImg] = useState();
  const [loading, setloading] = useState();
  const [vehicles, setvehicles] = useState([]);
  const [urls, seturls] = useState([]);
  const [repeatedname, setrepeatedname] = useState();
  const [file, setfile] = useState([]);
  const [disabled, setdisabled] = useState(false);
  const [errorUrl, seterrorUrl] = useState(false);
  const [details, setdetails] = useState([]);

  const VALUE_SIGN_VALIDATION = () => {
    return Yup.object({
      name: Yup.string().required("الزامی است"),
      category: Yup.string().required("الزامی است"),
      desc: Yup.string().required("الزامی است"),
    });
  };

  const { user, setUser } = useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      category: "",
      name: "",
      desc: "",
      price: "",
      voice: false,
    },
    validationSchema: VALUE_SIGN_VALIDATION,
    onSubmit: async (values) => {
      setloading(true);
      const formdata = new FormData();
      formdata.append("name", values.name);
      formdata.append("category", values.category);
      formdata.append("desc", values.desc);
      formdata.append("price", values?.price);
      formdata.append("voice", values.voice);
      if (img) {
        formdata.append("img", img, img?.name);
      }
      if (file) {
        for (let index = 0; index < file.length; index++) {
          formdata.append("file", file[index], file[index]?.name);
        }
      }

      if (images) {
        for (let index = 0; index < images.length; index++) {
          formdata.append("images", images[index], images[index]?.name);
        }
      }

      await fetch(BASE_URL + "/product", {
        method: "POST",
        body: formdata,
        headers: { "x-access-token": user.token },
      }).then((dat) => {
        if (dat?.status == 201) {
          toast.success("محصول ساخته شد");
          setloading(false);
          formik.resetForm();
          setImg(null);
          setImages([]);
          setPreviewImg(null);
          setPreview([]);
        } else {
          const message = dat.json();
          setloading(false);
          toast.error(message?.message);
        }
      });
    },
  });

  useEffect(() => {
    getData();
  }, []);

  function handleImg(e) {
    const fils = e.target.files[0];
    if (fils.size > 2031517) {
      toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
    } else {
      setImg(fils);
      setPreviwImage(fils);
    }
  }

  function handleImages(e) {
    const fils = e.target.files;
    if (fils) {
      for (let index = 0; index < fils?.length; index++) {
        if (fils[index].size > 2031517) {
          toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
        } else {
          if (images.length > 5) {
            toast.error("تعداد عکس ها بیشتر از 6 عدد نمی شود");
          } else {
            setImages((current) => [...current, fils[index]]);
            setPreviwImages(fils[index]);
          }
        }
      }
    }
  }

  async function setPreviwImages(i) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreview((current) => [...current, reader.result]);
    };
    reader.readAsDataURL(i);
  }

  async function setPreviwImage(imgInput) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreviewImg(reader.result);
    };
    reader.readAsDataURL(imgInput);
  }

  async function getData() {
    await httpReauest("GET", "/product/category", {}, {}).then(({ data }) => {
      seturls(data.data);
    });
  }

  function handleChange(params) {
    const value = params.target.value;
    const formattedValue = (
      Number(value.replace(/\D/g, "")) || ""
    ).toLocaleString();
    formik.setFieldValue("price", formattedValue);
  }

  async function deleteImg(index) {
    setPreview((students) => students.filter((s, i) => i != index));
    setImages((students) => students.filter((s, i) => i != index));
  }

  async function handleDeleteFile(index) {
    setfile((students) => students.filter((s, i) => i != index));
  }

  return (
    <div>
      <h2 className="text-[20px] mb-8 font-bold p-3">فرم ایجاد محصول</h2>
      <form
        onSubmit={formik.handleSubmit}
        className="grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-4 gap-6"
      >
        <span className="relative">
          <input
            id="name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="w-full form-brand h-[48px]"
          />

          {formik.errors.name && formik.touched.name && (
            <small style={{ color: "red", fontSize: "14px" }}>
              {formik.errors.name}
            </small>
          )}
          <p className="on-border font-bold">نام محصول*</p>
        </span>

        <span className="relative">
          <input
            id="price"
            name="price"
            value={formik.values.price}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="w-full form-brand h-[48px]"
          />

          {formik.errors.price && formik.touched.price && (
            <small style={{ color: "red", fontSize: "14px" }}>
              {formik.errors.price}
            </small>
          )}
          <p className="on-border font-bold">قیمت</p>
        </span>

        <span className="relative">
          <select
            id="voice"
            name="voice"
            value={formik.values.voice}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="w-full form-brand h-[48px]"
          >
            <option value={false}>فایل pdf</option>
            <option value={true}>فایل صوتی</option>
          </select>
          <p className="on-border font-bold">نوع فایل*</p>
        </span>

        <span className="relative">
          <select
            id="category"
            name="category"
            value={formik.values.category}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="w-full form-brand h-[48px]"
          >
            <option></option>
            {urls?.map((item) => (
              <option value={item._id}>{item.name}</option>
            ))}
          </select>
          {formik.errors.category && formik.touched.category && (
            <small style={{ color: "red", fontSize: "14px" }}>
              {formik.errors.category}
            </small>
          )}
          <p className="on-border font-bold">دسته بندی*</p>
        </span>

        <label
          htmlFor="img"
          className="relative w-full border-dashed border rounded-[10px] p-3 h-[200px] cursor-pointer border-[#444]"
        >
          {previewImg ? (
            <img
              src={previewImg}
              className="w-full h-full object-contain"
              alt=""
            />
          ) : (
            <FaPlus className="center" size={30} />
          )}

          <p className="on-border font-bold">تصویر اصلی</p>
        </label>
        <span>
          <p className="mb-3 font-bold">فایل های محصول</p>
          <input
            onChange={(e) => {
              setfile((cur) => [...cur, e.target.files[0]]);
            }}
            type="file"
          />
          {file.map((item, i) => (
            <span className="w-full flex justify-between py-2">
              {item.name?.slice(0, 15)}
              <FaTrash
                onClick={() => handleDeleteFile(i)}
                className="text-red-500"
              />
            </span>
          ))}
        </span>
        <input
          onChange={(e) => {
            handleImg(e);
          }}
          type="file"
          className="hidden"
          id="img"
          accept="image/png, image/jpg, image/jpeg"
        />

        <span className="relative col-span-full lg:col-span-2">
          <textarea
            id="desc"
            name="desc"
            value={formik.values.desc}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={disabled}
            className="w-full form-brand min-h-[88px]"
          />
          <p className="on-border font-bold">توضیحات</p>
        </span>
        <div className="relative col-span-full w-full border-dashed border rounded-[10px] p-3 min-h-[340px]  border-[#444]">
          {previews?.length > 0 ? (
            <div
              className="p-3"
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "15px",
              }}
            >
              {previews &&
                previews.map((src, i) => {
                  return (
                    <span className="relative">
                      <img
                        src={src}
                        style={{
                          width: "150px",
                          height: "150px",
                          objectFit: "contain",
                        }}
                        alt=""
                      />
                      <FaTrash
                        color="red"
                        className="top-0 right-0 absolute"
                        onClick={() => deleteImg(i)}
                      />
                    </span>
                  );
                })}
              <label htmlFor="images" className="cursor-pointer">
                <FaPlus size={50} />
              </label>
            </div>
          ) : (
            <label htmlFor="images" className="cursor-pointer">
              <FaPlus className="center" size={50} />
            </label>
          )}

          <p className="on-border font-bold">تصاویر</p>
        </div>
        <input
          onChange={(e) => {
            handleImages(e);
          }}
          type="file"
          className="hidden"
          id="images"
          accept="image/png, image/jpg, image/jpeg"
        />
        {loading ? (
          <Loading />
        ) : (
          <button type="submit" className="btn-brand">
            ارسال
          </button>
        )}
      </form>
    </div>
  );
};

export default CreateProductForm;
