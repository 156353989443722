import { useFormik } from "formik";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { FaPlus, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import { httpReauest } from "../../../utils/httpRequest";
import * as Yup from "yup";
import { BASE_URL, CDN_BASE_URL } from "../../../config";
import Loading from "../Loading";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import ReactSelect from "react-select";
import CustomModal from "../Modal";



const EditProductForm = () => {
  const [img, setImg] = useState();
  const [images, setImages] = useState([]);
  const [previews, setPreview] = useState([]);
  const [urls, seturls] = useState([]);
  const [previewImg, setPreviewImg] = useState();
  const [vehicles, setVehicles] = useState();
  const [selectedVehicle, setselectedVehicle] = useState();
  const [loading, setloading] = useState();
  const [file, setfile] = useState([]);
  const [show, setshow] = useState();
  const [details, setdetails] = useState([]);
  const [findedUrls, setfindedUrls] = useState([]);

  function getFileName(filePath) {
    // جدا کردن مسیر با استفاده از '/' و '\' و سپس گرفتن آخرین بخش
    const parts = filePath.split(/[/\\]/);
    const fileNameWithExtension = parts.pop(); // آخرین بخش را می‌گیرد

    // حذف شماره‌ها و نگه‌داشتن فقط نام فایل و پسوند
    const fileName = fileNameWithExtension.replace(/-.*(?=\.)/, ""); // حذف همه چیز بعد از '-' و قبل از '.'

    return fileName;
  }

  const toDataURL = (url) => {
    return fetch(url)
      .then((response) => {
        // بررسی وضعیت پاسخ
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const file = new File([blob], getFileName(url), {
              type: blob.type || "application/octet-stream", // نوع MIME پیش‌فرض
            });
            resolve(file);
          };
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      });
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const VALUE_SIGN_VALIDATION = () => {
    return Yup.object({
      name: Yup.string().required("الزامی است"),
      category: Yup.string().required("الزامی است"),
      desc: Yup.string().required("الزامی است"),
    });
  };

  async function deleteImg(index) {
    setPreview((students) => students.filter((s, i) => i != index));
    setImages((students) => students.filter((s, i) => i != index));
  }

  const { user, setUser } = useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      category: "",
      name: "",
      desc: "",
      price: "",
      voice: false,
    },
    validationSchema: VALUE_SIGN_VALIDATION,
    onSubmit: async (values) => {
      setloading(true);

      const formdata = new FormData();
      formdata.append("name", values.name);
      formdata.append("category", values.category);
      formdata.append("desc", values.desc);
      formdata.append("voice", values.voice);
      formdata.append("price", values?.price);
      if (img) {
        formdata.append("img", img, img?.name);
      }

      if (file) {
        for (let index = 0; index < file.length; index++) {
          formdata.append("file", file[index], file[index]?.name);
        }
      }

      if (images) {
        for (let index = 0; index < images.length; index++) {
          formdata.append("images", images[index], images[index]?.name);
        }
      }

      await fetch(BASE_URL + "/product/" + selectedVehicle?._id, {
        method: "PUT",
        body: formdata,
        headers: { "x-access-token": user.token },
      }).then(async (dat) => {
        if (dat?.status == 201) {
          toast.success("محصول تغییر یافت");
          setloading(false);
          formik.resetForm();
          setImg(null);
          setImages([]);
          setPreviewImg(null);
          setPreview([]);
          setdetails([]);
        } else {
          setloading(false);
          const message = await dat.json();
          toast.error(message?.message);
        }
      });
    },
  });

  useEffect(() => {
    getData();
  }, []);

  function handleImg(e) {
    const fils = e.target.files[0];
    if (fils.size > 2031517) {
      toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
    } else {
      setImg(fils);
      setPreviwImage(fils);
    }
  }

  function handleImages(e) {
    const fils = e.target.files;
    if (fils) {
      for (let index = 0; index < fils?.length; index++) {
        if (fils[index].size > 2031517) {
          toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
        } else {
          setImages((current) => [...current, fils[index]]);
          setPreviwImages(fils[index]);
        }
      }
    }
  }

  async function setPreviwImages(i) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreview((current) => [...current, reader.result]);
    };
    reader.readAsDataURL(i);
  }

  async function setPreviwImage(imgInput) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreviewImg(reader.result);
    };
    reader.readAsDataURL(imgInput);
  }

  async function getData() {
    await httpReauest("GET", "/product/category", {}, {}).then(({ data }) => {
      seturls(data.data);
    });

    await httpReauest("GET", "/product", {}, {}).then(({ data }) => {
      setVehicles(data.data);
    });
  }
  async function handleChange(e) {
    setImages([]);
    setPreview([]);
    setfile([]);
    const { data } = await httpReauest("GET", "/product/" + e, {}, {});

    const newVehicle = data?.data;

    setselectedVehicle(newVehicle);
    formik.setFieldValue("name", newVehicle.name);
    formik.setFieldValue("desc", newVehicle.desc);
    formik.setFieldValue("voice", newVehicle.voice);
    formik.setFieldValue("price", newVehicle?.price);
    formik.setFieldValue("category", newVehicle.category._id);

    setPreviewImg(CDN_BASE_URL + newVehicle?.img);

    newVehicle?.images?.map((e) => {
      toDataURL(CDN_BASE_URL + e)
        .then((file) => {
          setImages((current) => [...current, file]);
        })
        .catch((e) => {
          console.log(e);
        });
      setPreview((current) => [...current, CDN_BASE_URL + e]);
    });
  }

  async function handleDelete(params) {
    await fetch(BASE_URL + "/product/" + selectedVehicle?._id, {
      method: "DELETE",
      headers: { "x-access-token": user.token },
    })
      .then((dat) => {
        if (dat?.status == 201) {
          toast.success("محصول حذف شد");
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
  }

  async function handleDeleteFile(index) {
    setfile((students) => students.filter((s, i) => i != index));
  }

  return (
    <div>
      <CustomModal show={show} onHide={() => setshow(false)} fit>
        <div className="w-[200px] flex justify-center flex-col items-center gap-2">
          <div>از حذف محصول اطمینان دارید؟</div>

          <button
            onClick={() => {
              handleDelete();
              setshow(false);
            }}
            className="btn-brand"
          >
            حذف
          </button>
        </div>
      </CustomModal>
      <h2 className="text-[20px] font-bold p-3">فرم تغییر محصول</h2>
      <h2 className="text-[20px] mb-8 font-bold px-3">
        محصولات ثبت شده:{vehicles?.length}
      </h2>
      <form
        onSubmit={formik.handleSubmit}
        className="grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-4 gap-6"
      >
        <span className="relative">
          <select
            id="vehicle"
            name="vehicle"
            value={selectedVehicle?._id}
            onChange={(e) => handleChange(e.target.value)}
            className={` w-full form-brand h-[48px]`}
          >
            <option>{loading && <Loading />}</option>
            {vehicles?.map((e) => {
              return <option value={e._id}>{e.name}</option>;
            })}
          </select>
          <p className="on-border font-bold">انتخاب محصول*</p>
        </span>

        {selectedVehicle && (
          <>
            <span className="relative">
              <input
                id="name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-full form-brand h-[48px]"
              />

              {formik.errors.name && formik.touched.name && (
                <small style={{ color: "red", fontSize: "14px" }}>
                  {formik.errors.name}
                </small>
              )}
              <p className="on-border font-bold">نام محصول*</p>
            </span>

            <span className="relative">
              <input
                id="price"
                name="price"
                value={formik.values.price}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-full form-brand h-[48px]"
              />

              {formik.errors.price && formik.touched.price && (
                <small style={{ color: "red", fontSize: "14px" }}>
                  {formik.errors.price}
                </small>
              )}
              <p className="on-border font-bold">قیمت</p>
            </span>

            <span className="relative">
              <select
                id="voice"
                name="voice"
                value={formik.values.voice}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-full form-brand h-[48px]"
              >
                <option value={false}>فایل pdf</option>
                <option value={true}>فایل صوتی</option>
              </select>
              <p className="on-border font-bold">نوع فایل*</p>
            </span>
            <span className="relative">
              <select
                id="category"
                name="category"
                value={formik.values.category}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-full form-brand h-[48px]"
              >
                <option></option>
                {urls?.map((item) => (
                  <option value={item._id}>{item.name}</option>
                ))}
              </select>
              {formik.errors.category && formik.touched.category && (
                <small style={{ color: "red", fontSize: "14px" }}>
                  {formik.errors.category}
                </small>
              )}
              <p className="on-border font-bold">دسته بندی*</p>
            </span>

            <label
              htmlFor="img"
              className="relative w-full border-dashed border rounded-[10px] p-3 h-[200px] cursor-pointer border-[#444]"
            >
              {previewImg ? (
                <img
                  src={previewImg}
                  className="w-full h-full object-contain"
                  alt=""
                />
              ) : (
                <FaPlus className="center" size={30} />
              )}

              <p className="on-border font-bold">تصویر اصلی</p>
            </label>
            <span>
              <p className="mb-3 font-bold">فایل محصول</p>
              <input
                onChange={(e) => {
                  setfile((cur) => [...cur, e.target.files[0]]);
                }}
                type="file"
                className="mb-2"
              />
              {file?.map((item, i) => (
                <span className="w-full flex justify-between py-2">
                  {item.name?.slice(0, 15)}
                  <FaTrash
                    onClick={() => handleDeleteFile(i)}
                    className="text-red-500"
                  />
                </span>
              ))}
            </span>
            <input
              onChange={(e) => {
                handleImg(e);
              }}
              type="file"
              className="hidden"
              id="img"
              accept="image/png, image/jpg, image/jpeg"
            />

            <span className="relative col-span-full lg:col-span-2">
              <textarea
                id="desc"
                name="desc"
                value={formik.values.desc}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-full form-brand min-h-[88px]"
              />
              <p className="on-border font-bold">توضیحات</p>
            </span>
            <div className="relative col-span-full w-full border-dashed border rounded-[10px] p-3 min-h-[340px]  border-[#444]">
              {previews?.length > 0 ? (
                <div
                  className="p-3"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "15px",
                  }}
                >
                  {previews &&
                    previews.map((src, i) => {
                      return (
                        <span className="relative">
                          <img
                            src={src}
                            style={{
                              width: "150px",
                              height: "150px",
                              objectFit: "contain",
                            }}
                            alt=""
                          />
                          <FaTrash
                            color="red"
                            className="top-0 right-0 absolute"
                            onClick={() => deleteImg(i)}
                          />
                        </span>
                      );
                    })}
                  <label htmlFor="images" className="cursor-pointer">
                    <FaPlus size={50} />
                  </label>
                </div>
              ) : (
                <label htmlFor="images" className="cursor-pointer">
                  <FaPlus className="center" size={50} />
                </label>
              )}

              <p className="on-border font-bold">تصاویر</p>
            </div>
            <input
              onChange={(e) => {
                handleImages(e);
              }}
              type="file"
              className="hidden"
              id="images"
              accept="image/png, image/jpg, image/jpeg"
            />
            <span className="col-span-full flex gap-4">
              {loading ? (
                <Loading />
              ) : (
                <>
                  <button className="btn-brand">تغییر محصول</button>
                  <button
                    type="button"
                    onClick={() => setshow(true)}
                    className=" btn-brand-outline"
                  >
                    {" "}
                    حذف محصول
                  </button>
                </>
              )}
            </span>
          </>
        )}
      </form>
    </div>
  );
};

export default EditProductForm;
