import { useFormik } from "formik";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { FaPlus, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import { httpReauest } from "../../../utils/httpRequest";
import * as Yup from "yup";
import { BASE_URL } from "../../../config";
import Loading from "../Loading";
import { AuthContext } from "../../../context/AuthContext";
import Select from "react-select";
import ReactQuill from "react-quill";
import { PortfolioCategory, PortfolioSubCategory } from "./EditPortfolioForm";

const CreatePortfolioForm = () => {
  const [img, setImg] = useState();
  const [logo, setlogo] = useState();
  const [images, setImages] = useState([]);
  const [previews, setPreview] = useState([]);
  const [previewImg, setPreviewImg] = useState();
  const [previewLogo, setPreviewLogo] = useState();
  const [loading, setloading] = useState();
  const [text, settext] = useState();
  const [subCategorys, setsubCategorys] = useState([]);

  const VALUE_SIGN_VALIDATION = () => {
    return Yup.object({
      name: Yup.string().required("الزامی است"),
    });
  };

  const { user, setUser } = useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      name: "",
      type: "",
      category: "",
      subCategory: "",
    },
    validationSchema: VALUE_SIGN_VALIDATION,
    onSubmit: async (values) => {
      setloading(true);
      const formdata = new FormData();
      formdata.append("name", values.name);
      formdata.append("type", values.type);
      formdata.append("category", values.category);
      formdata.append("subCategory", values.subCategory);
      formdata.append("desc", text);
      if (img) {
        formdata.append("img", img, img?.name);
      }
      if (logo) {
        formdata.append("logo", logo, logo?.name);
      }
      if (images) {
        for (let index = 0; index < images.length; index++) {
          formdata.append("images", images[index], images[index]?.name);
        }
      }

      await fetch(BASE_URL + "/portfolio", {
        method: "POST",
        body: formdata,
        headers: { "x-access-token": user.token },
      }).then((dat) => {
        if (dat?.status == 201) {
          toast.success("نمونه کار ساخته شد");
          setloading(false);
          formik.resetForm();
          setImg(null);
          setImages([]);
          setPreviewImg(null);
          setPreview([]);
        } else {
          const message = dat.json();
          setloading(false);
          toast.error(message?.message);
        }
      });
    },
  });

  function handleImg(e) {
    const fils = e.target.files[0];
    if (fils.size > 2031517) {
      toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
    } else {
      setImg(fils);
      setPreviwImage(fils);
    }
  }

  function handleLogo(e) {
    const fils = e.target.files[0];
    if (fils.size > 2031517) {
      toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
    } else {
      setlogo(fils);
      setPreviwLogo(fils);
    }
  }

  function handleImages(e) {
    const fils = e.target.files;
    if (fils) {
      for (let index = 0; index < fils?.length; index++) {
        if (fils[index].size > 2031517) {
          toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
        } else {
          if (images.length > 5) {
            toast.error("تعداد عکس ها بیشتر از 6 عدد نمی شود");
          } else {
            setImages((current) => [...current, fils[index]]);
            setPreviwImages(fils[index]);
          }
        }
      }
    }
  }

  async function setPreviwImages(i) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreview((current) => [...current, reader.result]);
    };
    reader.readAsDataURL(i);
  }

  async function setPreviwImage(imgInput) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreviewImg(reader.result);
    };
    reader.readAsDataURL(imgInput);
  }

  async function setPreviwLogo(imgInput) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreviewLogo(reader.result);
    };
    reader.readAsDataURL(imgInput);
  }

  async function deleteImg(index) {
    setPreview((students) => students.filter((s, i) => i != index));
    setImages((students) => students.filter((s, i) => i != index));
  }

  useEffect(() => {
    if (formik.values.category) {
      const finded = PortfolioCategory.find(
        (e) => e.name === formik.values.category
      );

      const filtered = PortfolioSubCategory.filter(
        (item) => item.category === finded.id
      );
      setsubCategorys(filtered);
    }
  }, [formik.values.category]);

  return (
    <div>
      <h2 className="text-[20px] mb-8 font-bold p-3">فرم ایجاد نمونه کار</h2>
      <form
        onSubmit={formik.handleSubmit}
        className="grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-4 gap-6"
      >
        <span className="relative">
          <input
            id="name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="w-full form-brand h-[48px]"
          />

          {formik.errors.name && formik.touched.name && (
            <small style={{ color: "red", fontSize: "14px" }}>
              {formik.errors.name}
            </small>
          )}
          <p className="on-border font-bold">نام نمونه کار*</p>
        </span>
        <span className="relative">
          <select
            id="type"
            name="type"
            value={formik.values.type}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="w-full form-brand h-[48px]"
          >
            <option></option>
            <option>مشاوره</option>
            <option>طراحی سایت و سئو</option>
            <option>تبلیغات</option>
            <option>کوچینگ</option>
            <option>تولید محتوا</option>
            <option>فرنچایز</option>
          </select>
          {formik.errors.type && formik.touched.type && (
            <small style={{ color: "red", fontSize: "14px" }}>
              {formik.errors.type}
            </small>
          )}
          <p className="on-border font-bold">خدمت*</p>
        </span>

        <span className="relative">
          <select
            id="category"
            name="category"
            value={formik.values.category}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="w-full form-brand h-[48px]"
          >
            <option></option>
            {PortfolioCategory.map((item) => (
              <option>{item.name}</option>
            ))}
          </select>
          {formik.errors.category && formik.touched.category && (
            <small style={{ color: "red", fontSize: "14px" }}>
              {formik.errors.category}
            </small>
          )}
          <p className="on-border font-bold">دسته بندی*</p>
        </span>

        <span className="relative">
          <select
            id="subCategory"
            name="subCategory"
            value={formik.values.subCategory}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="w-full form-brand h-[48px]"
          >
            <option></option>
            {subCategorys.map((item) => (
              <option>{item.name}</option>
            ))}
          </select>
          {formik.errors.subCategory && formik.touched.subCategory && (
            <small style={{ color: "red", fontSize: "14px" }}>
              {formik.errors.subCategory}
            </small>
          )}
          <p className="on-border font-bold">زیر دسته بندی*</p>
        </span>

        <label
          htmlFor="img"
          className="relative w-full border-dashed border rounded-[10px] p-3 h-[200px] cursor-pointer border-[#444]"
        >
          {previewImg ? (
            <img
              src={previewImg}
              className="w-full h-full object-contain"
              alt=""
            />
          ) : (
            <FaPlus className="center" size={30} />
          )}

          <p className="on-border font-bold">تصویر اصلی</p>
        </label>

        <label
          htmlFor="logo"
          className="relative w-full border-dashed border rounded-[10px] p-3 h-[200px] cursor-pointer border-[#444]"
        >
          {previewLogo ? (
            <img
              src={previewLogo}
              className="w-full h-full object-contain"
              alt=""
            />
          ) : (
            <FaPlus className="center" size={30} />
          )}

          <p className="on-border font-bold">لوگو</p>
        </label>
        <input
          onChange={(e) => {
            handleLogo(e);
          }}
          type="file"
          className="hidden"
          id="logo"
          accept="image/png, image/jpg, image/jpeg"
        />

        <input
          onChange={(e) => {
            handleImg(e);
          }}
          type="file"
          className="hidden"
          id="img"
          accept="image/png, image/jpg, image/jpeg"
        />

        <span dir="ltr" className="relative col-span-full">
          <p className="font-bold text-right">توضیحات</p>
          <ReactQuill
            theme="snow"
            id="desc"
            value={text}
            onChange={(e) => settext(e)}
          />
        </span>

        <div className="relative col-span-full w-full border-dashed border rounded-[10px] p-3 min-h-[340px]  border-[#444]">
          {previews?.length > 0 ? (
            <div
              className="p-3"
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "15px",
              }}
            >
              {previews &&
                previews.map((src, i) => {
                  return (
                    <span className="relative">
                      <img
                        src={src}
                        style={{
                          width: "150px",
                          height: "150px",
                          objectFit: "contain",
                        }}
                        alt=""
                      />
                      <FaTrash
                        color="red"
                        className="top-0 right-0 absolute"
                        onClick={() => deleteImg(i)}
                      />
                    </span>
                  );
                })}
              <label htmlFor="images" className="cursor-pointer">
                <FaPlus size={50} />
              </label>
            </div>
          ) : (
            <label htmlFor="images" className="cursor-pointer">
              <FaPlus className="center" size={50} />
            </label>
          )}

          <p className="on-border font-bold">تصاویر</p>
        </div>
        <input
          onChange={(e) => {
            handleImages(e);
          }}
          type="file"
          className="hidden"
          id="images"
          accept="image/png, image/jpg, image/jpeg"
        />
        {loading ? (
          <Loading />
        ) : (
          <button type="submit" className="btn-brand">
            ارسال
          </button>
        )}
      </form>
    </div>
  );
};

export default CreatePortfolioForm;
