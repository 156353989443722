import React, { useContext, useEffect, useState } from "react";
import { httpReauest } from "../../../utils/httpRequest";
import { BASE_URL, CDN_BASE_URL } from "../../../config";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { FaPlus } from "react-icons/fa";
import Loading from "../Loading";
import { AuthContext } from "../../../context/AuthContext";
import ReactQuill from "react-quill";

const EditRouteForm = () => {
  const [brands, setBrands] = useState();
  const [img, setImg] = useState();
  const [img2, setImg2] = useState();
  const [previewImg, setPreviewImg] = useState();
  const [previewImg2, setPreviewImg2] = useState();
  const [loading, setloading] = useState();
  const [selectedBrand, setselectedBrand] = useState();
  const [text, settext] = useState();

  useEffect(() => {
    stayLogin();
  }, []);

  async function stayLogin() {
    await httpReauest("GET", "/routes", {}, {}).then(({ data }) => {
      setBrands(data?.data);
    });
  }

  const VALUE_SIGN_VALIDATION = () => {
    return Yup.object({
      url: Yup.string().required("الزامی است"),
    });
  };

  const { user, setUser } = useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      content: "",
      meta_desc: "",
      meta_title: "",
      url: "",
    },
    validationSchema: VALUE_SIGN_VALIDATION,
    onSubmit: async (values) => {
      setloading(true);

      await httpReauest("POST", "/routes", values, {
        "x-access-token": user.token,
      })
        .then((dat) => {
          if (dat?.status == 201) {
            toast.success("صفحه تغییر یافت");
            setloading(false);
            formik.resetForm();
          }
        })
        .catch((err) => {
          setloading(false);
          toast.error(err.response?.data?.message);
        });
    },
  });

  async function handleDelete(params) {
    await fetch(BASE_URL + "/category/" + selectedBrand?._id, {
      method: "DELETE",
      headers: { "x-access-token": user.token },
    })
      .then(async (dat) => {
        if (dat?.status == 201) {
          toast.success("صفحه حذف شد");
        } else {
          const message = await dat.json();
          toast.error(message.message);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
  }

  async function handleChange(e) {
    const newBrand = await brands.find((value) => value.url == e.target.value);

    setselectedBrand(newBrand);
    formik.setFieldValue("content", newBrand?.content);
    formik.setFieldValue("meta_title", newBrand?.meta_title);
    formik.setFieldValue("meta_desc", newBrand?.meta_desc);
    formik.setFieldValue("url", newBrand?.url);
  }

  return (
    <div>
      <h2 className="text-[20px] mb-8 font-bold p-3">فرم تغییر صفحه</h2>
      <form
        onSubmit={formik.handleSubmit}
        className="grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-4 gap-6"
      >
        <span className="relative">
          <select
            onChange={handleChange}
            style={{ paddingLeft: "45px" }}
            className={` w-full form-brand h-[48px]`}
          >
            <option></option>
            {brands?.map((e) => {
              if (!e.end) {
                return (
                  <option dir="ltr" value={e?.url}>
                    {e?.url}
                  </option>
                );
              }
            })}
          </select>
          <p className="on-border font-bold">انتخاب صفحه*</p>
        </span>
        {selectedBrand && (
          <>
            <span className="relative">
              <input
                id="meta_title"
                name="meta_title"
                value={formik.values.meta_title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-full form-brand h-[48px]"
              />
              {formik.errors.meta_title && formik.touched.meta_title && (
                <small style={{ color: "red", fontSize: "14px" }}>
                  {formik.errors.meta_title}
                </small>
              )}
              <p className="on-border font-bold">متا تایتل*</p>
            </span>
            <span className="relative">
              <textarea
                id="meta_desc"
                name="meta_desc"
                value={formik.values.meta_desc}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-full form-brand h-[78px]"
              />
              {formik.errors.meta_desc && formik.touched.meta_desc && (
                <small style={{ color: "red", fontSize: "14px" }}>
                  {formik.errors.meta_desc}
                </small>
              )}
              <p className="on-border font-bold">متا دیسکریپشن*</p>
            </span>
            <span dir="ltr" className="relative col-span-full">
              <p className="font-bold text-right">توضیحات</p>
              <ReactQuill
                theme="snow"
                id="content"
                value={formik.values.content}
                onChange={(e) => formik.setFieldValue("content", e)}
              />
            </span>
            <span className="col-span-full flex gap-4">
              {loading ? (
                <Loading />
              ) : (
                <>
                  <button className="btn-brand">تغییر صفحه</button>
                </>
              )}
            </span>
          </>
        )}
      </form>
    </div>
  );
};

export default EditRouteForm;
